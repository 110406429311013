<template>
    <div class="order-transactions">
        <md-table-card>
            <md-card-header>
                <h2 class="md-title">Order Transactions</h2>
            </md-card-header>
            <md-card-content>
                <md-table>
                    <md-table-header>
                        <md-table-row>
                            <md-table-head>Date</md-table-head>
                            <md-table-head>PayNo</md-table-head>
                            <md-table-head>Status</md-table-head>
                            <md-table-head>Amount</md-table-head>
                            <md-table-head>Payment</md-table-head>
                            <md-table-head>Source</md-table-head>
                            <md-table-head>Gateway</md-table-head>
                            <md-table-head>Actions</md-table-head>
                        </md-table-row>
                    </md-table-header>
                    <md-table-body>
                        <md-table-row :class="{ child: p.status == 'refund' && p.pid }" :key="ix" v-for="(p, ix) in pays">
                            <md-table-cell>{{ p.updatedAt | date }}</md-table-cell>
                            <md-table-cell>{{ p.payNo }}</md-table-cell>
                            <md-table-cell>
                                <md-chip :class="{ 'md-primary': p.status == 'payment', 'md-warn': p.status == 'refund' }">{{ p.status }}</md-chip>
                            </md-table-cell>
                            <md-table-cell>{{ p.amount | currency }}</md-table-cell>
                            <md-table-cell>
                                <span>{{ p.brand }} ****{{ p.card }}</span>
                                <br />
                                <small>{{ p.holdername }}</small>
                            </md-table-cell>
                            <md-table-cell>{{ p.source }}</md-table-cell>
                            <md-table-cell>{{ p.gateway }} {{ p.gatewayType }}</md-table-cell>
                            <md-table-cell>
                                <md-button @click="openDetail(p)" class="md-raised md-primary">detail</md-button>
                                <md-button :disabled="p.amount <= p.refund" @click="goRefund(p)" class="md-raised md-warn" v-if="p.status != 'refund'">refund</md-button>
                            </md-table-cell>
                        </md-table-row>
                    </md-table-body>
                </md-table>
            </md-card-content>
        </md-table-card>
        <order-transaction-stripe-detail :pay="selected" ref="stripe-detail"></order-transaction-stripe-detail>
        <order-transaction-tilled-detail :pay="selected" ref="tilled-detail"></order-transaction-tilled-detail>
        <order-transaction-detail :pay="selected" ref="detail"></order-transaction-detail>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import OrderTransactionStripeDetail from "@/components/Order/OrderTransactionStripeDetail";
import OrderTransactionTilledDetail from "@/components/Order/OrderTransactionTilledDetail";
import OrderTransactionDetail from "@/components/Order/OrderTransactionDetail";

export default {
    props: ["id"],
    components: {
        OrderTransactionStripeDetail,
        OrderTransactionTilledDetail,
        OrderTransactionDetail
    },
    data() {
        return {
            pays: [],
            selected: {}
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasPermission: "user/hasPermission"
        })
    },
    methods: {
        async getPays() {
            let result = await this.$store.dispatch("crud/get", {
                api: `pays`,
                params: {
                    orderId: this.id
                }
            });
            var pays = [];
            var payments = result.data.filter(r => r.status == "payment");
            var refunds = result.data.filter(r => r.status == "refund");
            payments.forEach(payment => {
                pays.push(payment);
                var childs = refunds.filter(r => r.pid == payment._id);
                refunds = refunds.filter(r => r.pid != payment._id);
                pays = pays.concat(childs);
            });
            pays = pays.concat(refunds);
            this.pays = pays;
        },
        async openDetail(p) {
            this.selected = p;
            if (p.gateway == "stripe") {
                this.$refs["stripe-detail"].open();
            } else if (p.gateway == "EzTimePay") {
                this.$refs["tilled-detail"].open();
            } else {
                this.$refs["detail"].open();
            }
        },
        async goRefund(pay) {
            // this.$emit('refund', pay);
            this.$router.push(`/admin/orders/refund/${this.id}?pid=${pay._id}`);
        }
    },
    mounted() {
        this.getPays();
    }
};
</script>
<style lang="less">
.order-transactions {
    .md-table .md-table-cell .md-button {
        width: 100px;
        margin-left: 0;
    }
    .md-table .md-table-cell.md-has-action {
        width: 220px;
    }
    .md-table .md-table-row.child {
        /* padding-left:10px; */
        /* transform: translate(10px); */
        background: #f6f6f6;
    }
}
</style>
