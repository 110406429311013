<template>
    <div class="order-email-list">
        <list :addLink="addLink" :api="api" :apiList="apiList" :fs="fs" :showDelete="showDelete" title="Order Email Sending Record"></list>
    </div>
</template>
<script>
import List from "@/components/List";
import { emailListFields } from "@/config";
import { mapGetters } from "vuex";
export default {
    props: {
        id: {
            type: String //orderId
        }
    },
    components: {
        List
    },
    computed: {
        ...mapGetters({
            hasRole: "user/hasRole"
        }),
        fs() {
            return emailListFields;
        },
        api() {
            return "emails";
        },
        apiList() {
            return `emails?orderId=${this.id}`;
        },
        addLink() {
            return `/admin/orders/email/${this.id}`;
        },
        showDelete() {
            if (this.hasRole("manager")) {
                return true;
            }
            return false;
        }
    }
};
</script>

