<template>
    <div class="order-payment">
        <form @submit.prevent="process" action>
            <md-card>
                <md-card-header>
                    <h1 class="md-title">Add Payment</h1>
                </md-card-header>
                <md-card-content>
                    <md-input-container>
                        <label for>Gateway</label>
                        <div>
                            <md-button v-if="showStripe" :class="{ 'md-primary': gateway == 'stripe' }" @click="gateway = 'stripe'" class="md-raised"> <md-icon>credit_card</md-icon>Stripe </md-button>
                            <md-button v-if="showTilled" :class="{ 'md-primary': gateway == 'EzTimePay' }" @click="gateway = 'EzTimePay'" class="md-raised">
                                <md-icon>credit_card</md-icon>Credit Card
                            </md-button>
                            <md-button :class="{ 'md-primary': gateway == 'pos' }" @click="gateway = 'pos'" class="md-raised"> <md-icon>credit_card</md-icon>Pos </md-button>
                        </div>
                        <md-input type="hidden"></md-input>
                    </md-input-container>

                    <md-input-container v-if="gateway == 'pos'">
                        <label for>PosType</label>
                        <md-select v-model="gatewayType">
                            <md-option value="cash">Cash</md-option>
                            <md-option value="check">Check</md-option>
                        </md-select>
                    </md-input-container>
                    <md-input-container v-if="gateway == 'pos' && gatewayType == 'check'">
                        <label for>Check Number</label>
                        <md-input v-model="checkNo"></md-input>
                    </md-input-container>

                    <!-- <md-input-container v-if="gateway == 'stripe'">
                        <label for>Card</label>
                        <md-input :readonly="true" :value="cardText"></md-input>
                    </md-input-container> -->

                    <md-input-container>
                        <label for>Amount</label>
                        <md-button class="md-raised">$ USD</md-button>
                        <md-input v-model="amount"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for>Notes</label>
                        <textarea v-model="note"></textarea>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <md-button @click="cancel">Cancel</md-button>
                    <md-button class="md-raised md-primary" type="submit">Pay</md-button>
                </md-card-actions>
            </md-card>
        </form>
        <pay-confirm :amount="amount" :type="gateway" :email="order.user_email" @confirm="payConfirm" ref="payConfirm" v-if="order"></pay-confirm>
        <md-dialog-confirm
            :md-content-html="'Please ensure that you have removed all Security Deposits from your booking before processing the final charge to complete the order.'"
            @close="securityConfirm"
            md-title="Confirm"
            ref="securityConfirm"
        ></md-dialog-confirm>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PayConfirm from "@/components/PayConfirm";

export default {
    props: {
        id: { type: String } //orderid
    },
    components: {
        PayConfirm
    },
    data() {
        return {
            gateway: "", //pos
            gatewayType: "cash", //check
            checkNo: "",
            order: null,
            card: null,
            amount: 0,
            note: ""
        };
    },
    computed: {
        ...mapGetters({
            user: "user/user",
            hasPermission: "user/hasPermission",
            paymentSetting: "setting/payment"
        }),
        showStripe() {
            return this.paymentSetting.value && this.paymentSetting.value.use_stripe;
        },
        showTilled() {
            return this.paymentSetting.value && this.paymentSetting.value.use_tilled;
        },
        remaining() {
            if (!this.order) return 0;
            if (this.order.status == "Cancelled") return 0;
            let { total, shipping, deposit, securityDeposit } = this.order.amount;
            let { discount } = this.order;
            let remaining = total + shipping + securityDeposit - (this.order.paid || 0) - (discount || 0);
            // todo check remaining 0.01
            return +remaining.toFixed(2);
        },
        cardText() {
            if (!this.card) {
                return "";
            }
            return `${this.card.brand} ****${this.card.last4}  -  ${this.card.name}`;
        }
    },
    watch: {
        remaining() {
            this.amount = this.remaining;
        },
        showStripe: {
            immediate: true,
            handler() {
                if (this.showStripe) {
                    this.gateway = "stripe";
                } else {
                    this.gateway = "EzTimePay";
                }
            }
        }
    },
    methods: {
        async getOrder() {
            let result = await this.$store.dispatch(`crud/get`, {
                api: `orders/${this.id}`
            });
            this.order = result;
            this.order.amount = this.order.amount || {};
            this.order.address = this.order.address || {};
        },
        async process() {
            this.$refs["securityConfirm"].open();
        },
        async securityConfirm(e) {
            if (e != "ok") {
                return;
            }
            if (this.gateway == "stripe" || this.gateway == "EzTimePay") {
                this.$refs["payConfirm"].open();
            }
            if (this.gateway == "pos") {
                if (this.gatewayType == "cash") {
                    this.checkNo = "";
                }
                let result = await this.$store.dispatch("crud/post", {
                    api: `pays/pos/pay/${this.order._id}`,
                    data: {
                        amount: this.amount,
                        note: this.note,
                        gatewayType: this.gatewayType,
                        checkNo: this.checkNo,
                        type: "addpayment"
                    }
                });
                this.$store.commit("setMessage", "Payment Success", { root: true });
                this.$router.push(`/admin/orders/transaction/${this.id}`);
            }
        },
        async payConfirm({ card }) {
            let result = await this.$store.dispatch("crud/post", {
                api: `pays/${this.gateway}/pay/${this.order._id}`,
                data: {
                    card,
                    amount: this.amount,
                    note: this.note,
                    type: "addpayment"
                }
            });
            this.$store.commit("setMessage", "Payment Success", { root: true });
            this.$router.push(`/admin/orders/transaction/${this.id}`);
        },
        cancel() {
            this.$router.push(`/admin/orders/transaction/${this.id}`);
        }
    },

    async mounted() {
        await this.getOrder();
        await this.$store.dispatch("setting/refreshPayment");
    }
};
</script>

<style lang="less">
@import "../../mixins.less";
.order-payment {
    .md-card {
        max-width: 1000px;
        margin: 0 auto;
    }

    .form;
}
</style>
