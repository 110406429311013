<template>
    <md-dialog class="order-transaction-tilled-detail" ref="dialog">
        <md-card v-if="charge">
            <md-card-header>
                <h2>Transaction EzTimePay Detail</h2>
            </md-card-header>
            <md-card-content v-if="pay.status == 'payment'">
                <md-input-container>
                    <label for>Gateway</label>
                    <span class="val">{{ pay.gateway }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>PayNo</label>
                    <span class="val">{{ pay.payNo }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Transaction ID</label>
                    <span class="val">{{ charge.id }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Date Created</label>
                    <span class="val">{{ charge.created_at }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Amount</label>
                    <span class="val">{{ (charge.amount / 100) | currency }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>App Fee</label>
                    <span class="val">{{ (charge.platform_fee_amount / 100) | currency }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <!-- <md-input-container>
                    <label for>Created By</label>
                    <span class="val">{{ charge.application.name }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container> -->
                <md-input-container>
                    <label for>Source</label>
                    <span class="val">ip: {{ pay.ip }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Payment Type</label>
                    <span class="val">{{ charge.payment_method.card.brand }} {{ charge.payment_method.card.last4 }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
            </md-card-content>
            <md-card-content v-if="pay.status == 'refund'">
                <md-input-container>
                    <label for>Gateway</label>
                    <span class="val">EzTimePay</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Transaction ID</label>
                    <span class="val">{{ refund.id }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Date Created</label>
                    <span class="val">{{ refund.created_at }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Amount</label>
                    <span class="val">{{ (refund.amount / 100) | currency }}</span>
                    <!-- <span class="val"
                        >{{ (refund.amount / 100) | currency }} = {{ (-refund.balance_transaction.amount / 100) | currency }} + {{ (refund.balance_transaction.fee / 100) | currency }} (app_fee)</span
                    > -->
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <!-- <md-input-container>
                    <label for>Created By</label>
                    <span class="val">{{ charge.application.name }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container> -->
                <md-input-container>
                    <label for>Source</label>
                    <span class="val">ip: {{ pay.ip }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Payment Type</label>
                    <span class="val">{{ charge.payment_method.card.brand }} {{ charge.payment_method.card.last4 }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for>Note</label>
                    <span class="val">{{ pay.note }}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
            </md-card-content>
            <md-card-actions>
                <md-button @click="close">Close</md-button>
            </md-card-actions>
        </md-card>
    </md-dialog>
</template>
<script>
export default {
    props: ["pay"],
    data() {
        return {
            charge: null,
            refund: null
        };
    },
    watch: {
        pay: {
            immediate: true,
            handler() {
                if (this.pay) {
                    this.charge = null;
                    this.refund = null;
                    this.getTilledDetail();
                }
            }
        }
    },
    computed: {
        // appRefundAmount() {
        //     if (this.pay.status == "refund" && this.charge && this.refund) {
        //         var refund = this.charge.refunds.data.find(r => r.id == this.refund.id);
        //         var index = this.charge.refunds.data.indexOf(refund);
        //         var appRefund = this.charge.application_fee.refunds.data[index];
        //         return appRefund.amount;
        //     }
        //     return 0;
        // }
    },
    methods: {
        async getTilledDetail() {
            if (this.pay.status == "payment" && this.pay.gateway == "EzTimePay") {
                let result = await this.$store.dispatch("crud/get", {
                    api: `EzTimePay/charge/${this.pay.tilledChargeId}`
                });
                this.charge = result;
            }
            if (this.pay.status == "refund" && this.pay.gateway == "EzTimePay") {
                let refund = await this.$store.dispatch("crud/get", {
                    api: `EzTimePay/refund/${this.pay.tilledRefundId}`
                });
                this.refund = refund;
                let parentPay = await this.$store.dispatch("crud/get", {
                    api: `pays/${this.pay.pid}`
                });
                let charge = await this.$store.dispatch("crud/get", {
                    api: `EzTimePay/charge/${parentPay.tilledChargeId}`
                });
                this.charge = charge;
            }
        },
        open() {
            this.$refs["dialog"].open();
        },
        close() {
            this.$refs["dialog"].close();
        }
    }
};
</script>
<style lang="less">
@import "../../mixins.less";
.order-transaction-tilled-detail {
    .md-dialog {
        width: 600px;
    }
    .form-small;

    @media (max-width: 600px) {
        .md-dialog {
            width: 300px;
        }
    }
}
</style>
