<template>
    <div class="order-refund">
        <form action="" @submit.prevent="process">
            <md-card style="max-width:1000px;margin:0 auto">
                <md-card-header>
                    <h1 class="md-title">Refund Payment</h1>
                </md-card-header>
                <md-card-content v-if="pay">
                    <md-input-container>
                        <label for="">Gateway</label>
                        <span class="val">{{ pay.gateway }} {{ pay.gatewayType }}</span>
                        <md-input type="hidden"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for="">Total</label>
                        <span class="val">{{ pay.amount | currency }}</span>
                        <md-input type="hidden"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for="">Refuned</label>
                        <span class="val">{{ pay.refund | currency }}</span>
                        <md-input type="hidden"></md-input>
                    </md-input-container>
                    <md-input-container>
                        <label for="">Amount</label>
                        <md-button class="md-raised">$ USD</md-button>
                        <md-input v-model="amount"></md-input>
                    </md-input-container>
                    <md-input-container v-if="pay.gateway == 'stripe' || pay.gateway == 'tilled'">
                        <label for="">Card</label>
                        <md-input :readonly="true" :value="cardText"></md-input>
                    </md-input-container>
                    <md-input-conatainer v-if="pay.gateway == 'pos' && pay.gatewayType == 'check'">
                        <label for="">Check Number</label>
                        <md-input :readonly="true" :value="pay.checkNo"></md-input>
                    </md-input-conatainer>
                    <md-input-container>
                        <label for="">Notes</label>
                        <textarea v-model="note"></textarea>
                    </md-input-container>
                </md-card-content>
                <md-card-actions>
                    <div></div>
                    <div>
                        <md-button @click="cancel">Cancel</md-button>
                        <md-button type="submit" class="md-raised md-primary">Refund</md-button>
                    </div>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>

<script>
export default {
    props: ["id"],
    components: {},
    data() {
        return {
            charge: null,
            pay: null, //payment record
            note: "",
            amount: 0
        };
    },
    computed: {
        cardText() {
            if (!this.pay) {
                return "";
            }
            return `${this.pay.brand} ****${this.pay.card}  -  ${this.pay.holdername}`;
        }
    },
    methods: {
        async getPay() {
            let result = await this.$store.dispatch("crud/get", {
                api: `pays/${this.$route.query.pid}`
            });
            this.pay = result;
            this.amount = this.pay.amount - this.pay.refund;
        },
        async process() {
            if (this.pay.gateway == "stripe") {
                let result = await this.$store.dispatch("crud/post", {
                    api: `pays/stripe/refund/${this.id}`,
                    data: {
                        amount: this.amount,
                        pid: this.pay._id,
                        note: this.note,
                        type: "refundpayment"
                    }
                });
            }

            if (this.pay.gateway == "EzTimePay") {
                let result = await this.$store.dispatch("crud/post", {
                    api: `pays/EzTimePay/refund/${this.id}`,
                    data: {
                        amount: this.amount,
                        pid: this.pay._id,
                        note: this.note,
                        type: "refundpayment"
                    }
                });
            }

            if (this.pay.gateway == "pos") {
                let result = await this.$store.dispatch("crud/post", {
                    api: `pays/pos/refund/${this.id}`,
                    data: {
                        amount: this.amount,
                        pid: this.pay._id,
                        note: this.note,
                        type: "refundpayment"
                    }
                });
            }

            this.$store.commit("setMessage", "Refund Success", { root: true });

            this.$router.push(`/admin/orders/transaction/${this.id}`);
        },
        cancel() {
            this.$router.push(`/admin/orders/transaction/${this.id}`);
        }
    },
    async mounted() {
        if (!this.$route.query.pid) {
            this.$store.dispatch("setMessage", "pid missing");
        }

        await this.getPay();
    }
};
</script>

<style lang="less">
@import "../../mixins.less";
.order-refund {
    .md-card {
        max-width: 1000px;
        margin: 0 auto;
    }
    .form;
}
</style>
