<template>
    <div class="order-edit-detail">
        <div class="container">
            <md-tabs @change="tabChange">
                <md-tab :md-active="tabIndex==0" md-label="Booking">
                    <order-detail-meta :id="id" mode="admin" v-if="tabIndex==0"></order-detail-meta>
                </md-tab>
                <md-tab :md-active="tabIndex==1" :md-disabled="user.role=='staff'&&!hasPermission('edit_order')" md-label="Edit">
                    <order-edit :id="id" v-if="tabIndex==1"></order-edit>
                </md-tab>
                <md-tab :md-active="tabIndex==2" :md-disabled="user.role=='staff'&&!hasPermission('edit_order_email')" md-label="E-mail">
                    <order-email :id="id" v-if="tabIndex==2"></order-email>
                </md-tab>
                <md-tab :md-active="tabIndex==3" :md-disabled="user.role=='staff'&&!hasPermission('edit_order')" md-label="Notes">
                    <order-note :id="id" v-if="tabIndex==3"></order-note>
                </md-tab>
                <md-tab :md-active="tabIndex==4" :md-disabled="user.role=='staff'&&!hasPermission('edit_order_payment_pos')" md-label="Add Payment">
                    <order-payment :id="id" v-if="tabIndex==4"></order-payment>
                </md-tab>
                <md-tab :md-active="tabIndex==5" :md-disabled="user.role=='staff'&&!hasPermission('edit_order_payment_pos')" md-label="Transactions">
                    <order-transactions :id="id" v-if="tabIndex==5"></order-transactions>
                </md-tab>
                <md-tab :md-active="tabIndex==6" :md-disabled="user.role=='staff'&&!hasPermission('edit_order_email')" md-label="E-mail Log">
                    <order-email-list :id="id" v-if="tabIndex==6"></order-email-list>
                </md-tab>
                <md-tab :md-active="tabIndex==7" :md-disabled="tabIndex!=7" md-label="Email Detail">
                    <order-email-detail :id="emailId" v-if="tabIndex==7 && emailId"></order-email-detail>
                </md-tab>
                <md-tab :md-active="tabIndex==8" :md-disabled="tabIndex!=8" md-label="Refund">
                    <order-refund :id="id" v-if="tabIndex==8"></order-refund>
                </md-tab>
                <md-tab :md-active="tabIndex==9" :md-disabled="tabIndex!=9" md-label="test">test</md-tab>
            </md-tabs>
            <router-link :to="'/admin/orders/manage'" class="md-icon-button close back" tag="md-button">
                <md-icon>keyboard_backspace</md-icon>
            </router-link>
        </div>
    </div>
</template>

<script>
import OrderDetailMeta from "@/components/Order/OrderDetailMeta";
import OrderEdit from "@/components/Order/OrderEdit";
import OrderEmail from "@/components/Order/OrderEmail";
import OrderEmailList from "@/components/Order/OrderEmailList";
import OrderEmailDetail from "@/components/Order/OrderEmailDetail";
import OrderPayment from "@/components/Order/OrderPayment";
import OrderRefund from "@/components/Order/OrderRefund";
import OrderTransactions from "@/components/Order/OrderTransactions";
import OrderNote from "@/components/Order/OrderNote";
import Submit from "@/components/Submit";
import { mapGetters } from "vuex";

export default {
    props: {
        id: {
            type: String
        },
        emailId: {
            type: String
        }
    },
    components: {
        OrderDetailMeta,
        OrderEdit,
        OrderEmail,
        OrderEmailList,
        OrderEmailDetail,
        OrderPayment,
        OrderRefund,
        OrderTransactions,
        OrderNote
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    computed: {
        ...mapGetters({
            hasPermission: "user/hasPermission",
            user: "user/user"
        })
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                var path = this.$route.path;
                if (/\/editdetail\//gi.test(path)) {
                    this.tabIndex = 0;
                } else if (/\/edit\//.test(path)) {
                    this.tabIndex = 1;
                } else if (/\/email\//.test(path)) {
                    this.tabIndex = 2;
                } else if (/note/.test(path)) {
                    this.tabIndex = 3;
                } else if (/payment/.test(path)) {
                    this.tabIndex = 4;
                } else if (/transaction/.test(path)) {
                    this.tabIndex = 5;
                } else if (/\/email-list\//.test(path)) {
                    this.tabIndex = 6;
                } else if (/\/email-detail\//.test(path)) {
                    this.tabIndex = 7;
                } else if (/refund/.test(path)) {
                    this.tabIndex = 8;
                } else if (/test/.test(path)) {
                    this.tabIndex = 9;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }
            if (index == 0) {
                this.$router.push(`/admin/orders/editDetail/${this.id}`);
            }
            if (index == 1) {
                this.$router.push(`/admin/orders/edit/${this.id}`);
            }
            if (index == 2) {
                this.$router.push(`/admin/orders/email/${this.id}`);
            }
            if (index == 3) {
                this.$router.push(`/admin/orders/note/${this.id}`);
            }
            if (index == 4) {
                this.$router.push(`/admin/orders/payment/${this.id}`);
            }
            if (index == 5) {
                this.$router.push(`/admin/orders/transaction/${this.id}`);
            }
            if (index == 6) {
                this.$router.push(`/admin/orders/email-list/${this.id}`);
            }
            if (index == 7) {
                // this.$router.push(`/admin/orders/email-detail/${this.id}?emailId=${}`);
            }
            if (index == 8) {
                // this.$router.push(`/admin/orders/refund/${this.id}`);
            }
        }
    }
};
</script>
<style lang="less">
.order-edit-detail {
    .container {
        position: relative;
    }
    .back {
        position: absolute;
        right: 10px;
        top: 4px;
        z-index: 9;
    }

    @media (max-width: 600px) {
        .back {
            display: none;
        }
    }
}
</style>