<template>
    <md-dialog ref="dialog" class="order-transaction-detail">
        <md-card v-if="pay">
            <md-card-header>
                <h2>Transaction Detail</h2>
            </md-card-header>
            <md-card-content>
                <md-input-container>
                    <label for="">Gateway</label>
                    <span class="val">{{pay.gateway}} {{pay.gatewayType}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">PayNo</label>
                    <span class="val">{{pay.payNo}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Status</label>
                    <span class="val">{{pay.status}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.status=='payment'">
                    <label for="">Amount</label>
                    <span class="val">{{pay.amount | currency}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Refunded</label>
                    <span class="val">{{pay.refund | currency}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">AppFee</label>
                    <span class="val">{{pay.appfee | currency}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">AppFeePaid</label>
                    <span class="val">{{pay.appfeePaid?'Yes':'No'}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Source</label>
                    <span class="val">{{pay.source}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='stripe'">
                    <label for="">Card Brand</label>
                    <span class="val">{{pay.brand}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='stripe'">
                    <label for="">Card holdername</label>
                    <span class="val">{{pay.holdername}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='stripe'">
                    <label for="">Card No</label>
                    <span class="val">{{pay.card}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='pos' && pay.gatewayType=='check'">
                    <label for="">Check Number</label>
                    <span class="val">{{pay.checkNo}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Ip</label>
                    <span class="val">{{pay.ip}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Note</label>
                    <span class="val">{{pay.note}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='stripe' && pay.status=='payment'">
                    <label for="">Stripe ChargeId</label>
                    <span class="val">{{pay.stripeChargeId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='stripe' && pay.status=='payment'">
                    <label for="">Stripe Refund Id</label>
                    <span class="val">{{pay.stripeRefundId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='pos' && pay.status=='payment'">
                    <label for="">Stripe AppFee Charge Id</label>
                    <span class="val">{{pay.stripeAppfeeChargeId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='pos' && pay.status=='refund '">
                    <label for="">Stripe AppFee Refund Id</label>
                    <span class="val">{{pay.stripeAppfeeRefundId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='pos'&& pay.status=='payment'">
                    <label for="">Stripe Invoice Id</label>
                    <span class="val">{{pay.stripeInvoiceId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container v-if="pay.gateway=='pos'&& pay.status=='payment'">
                    <label for="">Stripe Invoice Item Id</label>
                    <span class="val">{{pay.stripeInvoiceItemId}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
                <md-input-container>
                    <label for="">Created At</label>
                    <span class="val">{{pay.createdAt | date}}</span>
                    <md-input type="hidden"></md-input>
                </md-input-container>
            </md-card-content>
            <md-card-actions>
                <md-button @click="close">Close</md-button>
            </md-card-actions>
        </md-card>
    </md-dialog>
</template>
<script>
export default {
    props: ["pay"],
    methods: {
        open() {
            this.$refs["dialog"].open();
        },
        close() {
            this.$refs["dialog"].close();
        }
    }
};
</script>
<style lang="less">
@import "../../mixins.less";
.order-transaction-detail {
    .form-small;
    .md-dialog {
        width: 600px;
    }
    .md-input-container {
        label {
            width: 200px;
        }
    }
    @media (max-width: 600px) {
        .md-dialog {
            width: 300px;
        }
    }
}
</style>

