<template>
    <div class="order-note">
        <edit :fs="orderNoteFields" :id="id" :title="title" @changed="changed" @saved="saved" api="orders"></edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { orderNoteFields } from "@/config";
import Submit from "@/components/Submit";

export default {
    props: ["id"],
    components: {
        Edit,
        Submit
    },
    data() {
        return {
            orderNo: null
        };
    },
    computed: {
        title() {
            return `OrderNo:${this.orderNo}`;
        },
        orderNoteFields() {
            return orderNoteFields;
        }
    },
    methods: {
        // async getOrder() {
        //     if (this.id) {
        //         var result = await this.$store.dispatch(`crud/get`, {
        //             api: `orders/${this.id}`
        //         });
        //         this.order = result;
        //     }

        //     if (!this.order) {
        //         this.$store.commit("setMessage", "Order Not Found");
        //     }
        // },
        async changed(order) {
            this.orderNo = order.orderNo;
        },
        async saved() {
            this.$store.commit("setMessage", "Save Note Success");
        }
    },
    mounted() {
        // this.getOrder();
    }
};
</script>
<style lang="less">
@import "../../mixins.less";
.order-note {
    max-width: 1000px;
    margin: 0 auto;
    textarea {
        min-width: 90%;
    }
}
</style>

