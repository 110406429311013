<template>
    <div class="order-email">
        <edit :fs="orderEmailFields" @saved="saved" api="notifications/orderEmail" ref="edit" title="E-mail Customer">
            <template slot="submit">
                <div class="submit-container">
                    <md-button @click="clear">Cancel</md-button>
                    <md-button class="md-raised md-primary" type="submit">Send</md-button>
                </div>
            </template>
        </edit>
    </div>
</template>
<script>
import Edit from "@/components/Edit";
import { orderEmailFields } from "@/config";
import EmailEditor from "@/components/EmailEditor";
export default {
    props: ["id"],
    components: {
        EmailEditor,
        Edit
    },
    data() {
        return {
            order: null
        };
    },
    computed: {
        orderEmailFields() {
            let fields = JSON.parse(JSON.stringify(orderEmailFields));
            return fields;
        }
    },

    methods: {
        async getOrder() {
            let result = await this.$store.dispatch(`crud/get`, {
                api: `orders/${this.id}`
            });
            this.order = result;
            this.clear();
        },
        saved(record) {
            this.$store.commit("setMessage", "Email Sent Succefully");
            this.clear();
        },
        clear() {
            // set defaults
            if (this.order) {
                let record = this.$refs["edit"].newRecord;
                this.$set(record, "_update", Math.random());
                this.$set(record, "to", this.order.user_email);
                this.$set(record, "from", "no_reply@eztimerental.com");
                this.$set(record, "replyto", this.order.vendor_email);
                this.$set(record, "subject", `Re. EzTimeRental Booking ID#${this.order.orderNo}`);
                this.$set(record, "orderId", this.order._id);
                this.$set(record, "html", "");
                this.$set(record, "cc", "");
                this.$set(record, "isIncludeInvoice", false);
            }
        }
    },
    async mounted() {
        await this.getOrder();
    }
};
</script>

<style lang="less">
.order-email {
    .md-card {
        max-width: 1000px;
        margin: auto;
        .md-card-actions {
            justify-content: flex-end;
        }
    }
}
</style>