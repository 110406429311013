<template>
    <div class="order-email-detail common-edit form" v-if="email">
        <md-card>
            <md-card-content>
                <md-input-container>
                    <label for>To</label>
                    <div class="val">{{email.to}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container>
                    <label for>From</label>
                    <div class="val">{{email.to}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container v-if="email.cc.filter(a=>a).length">
                    <label for>cc</label>
                    <div class="val">{{email.cc.join(' ')}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container v-if="email.bcc.filter(a=>a).length">
                    <label for>bcc</label>
                    <div class="val">{{email.bcc.join(' ')}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container v-if="email.replyto">
                    <label for>Reply to</label>
                    <div class="val">{{email.replyto}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container>
                    <label for>Date</label>
                    <div class="val">{{email.createdAt | date}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container>
                    <label for>Subject</label>
                    <div class="val">{{email.subject}}</div>
                    <md-input type="hidden"></md-input>
                </md-input-container>

                <md-input-container>
                    <label for>Message</label>
                    <div class="val" style="padding-top:10px;" v-html="email.html"></div>
                    <md-input type="hidden"></md-input>
                </md-input-container>
            </md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    props: {
        id: {
            type: String // email Id
        }
    },
    data() {
        return {
            email: null
        };
    },
    methods: {
        async loadEmail() {
            if (this.id) {
                let result = await this.$store.dispatch("crud/get", {
                    api: `emails/${this.id}`
                });
                this.email = result;
            }
        }
    },
    created() {
        console.info(this.id);
        this.loadEmail();
    }
};
</script>
